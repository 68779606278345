/**
 * Bio component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import styled from 'styled-components';
import { InstagramOutlined } from '@ant-design/icons';

import { rhythm } from '../utils/typography';

function Bio() {
  return (
    <StaticQuery
      query={bioQuery}
      render={(data) => {
        const { author, social } = data.site.siteMetadata;
        return (
          <div style={{ margin: '30px 0px' }}>
            <InstagramOutlined style={{ marginRight: 10 }} />
            <a href="https://www.instagram.com/furrrypotatoes/">Make sure to follow us on instagram!</a>
          </div>
        );
      }}
    />
  );
}

const bioQuery = graphql`
  query BioQuery {
    mopInBowl: file(absolutePath: { regex: "/mop-in-bowl.jpg/" }) {
      childImageSharp {
        fixed(height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
        social {
          twitter
        }
      }
    }
  }
`;

export default Bio;
